import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import axios from "axios";
import API_URL from "../../Config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $, { data } from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net";
import "datatables.net-bs5"; // Import DataTables Bootstrap 5 integration CSS
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css"; //
import ReactDOM from "react-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { NumericFormat } from "react-number-format";
import Line_Chart from "../Charts/Line_Chart";
import Select from "react-select";

const Show_Clients = ({ data }) => {
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const [get_clients, set_clients] = useState([]);

  const handle_get_all_clients = async () => {
    await axios.post(`${API_URL}/get_all_clients`).then((res) => {
      set_clients(res.data.data);
    });
  };

  useEffect(() => {
    handle_get_all_clients();
  }, [data]);

  const handle_delete_client = async(id)=>{
    const ans = window.confirm('Are You Sure...')
    if(ans){
      await axios.post(`${API_URL}/delete_client` , { id : id }).then((res)=>{
        if(res.data.success == true){
          toast.success(`${res.data.data}`)
          handle_get_all_clients();
        }
        else{
          toast.success(`${res.data.data}`)
  
        }
      })
    }
  }

  useEffect(() => {
    if (get_clients.length > 0 && !tableRef.current) {
      tableRef.current = $("#myTable1").DataTable({
        // responsive: true,
        data: get_clients,
        columns: [
          {
            title: "No.",
            data: null,
            render: function (data, type, row, meta) {
              return `<div>${meta.row + 1}</div>`;
            },
            width: "5px",
          },
          { title: "Client Name", data: "c_name", width: "10px" },
          { title: "Address", data: null, width: "50px" },
          { title: "Email", data: "c_email", width: "10px" },
          { title: "Phone No.", data: "c_ph_no", width: "10px" },
          { title: "GSTIN", data: "c_gstin", width: "10px" },
          { title: "Country", data: "c_country", width: "10px" },
          { title: "City", data: "c_city", width: "10px" },

          { title: "Actions", data: null, width: "1px" },
          // { title: "Status", data: "status", width: "60px"  },
        ],
        createdRow: function (row, data, dataIndex) {
          ReactDOM.render(
            <div style={{ width : "500px" , textWrap : "wrap" , textAlign : "justify" }}>
              {data.c_addr}
            </div>,
            row.cells[2] // Render buttons in the last cell
          );
          ReactDOM.render(
            <div style={{ display: "flex", gap: "10px" }}>
              <div class="dropdown">
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column-reverse",
                  }}
                >
                  <MoreHorizIcon />
                </button>
                <ul
                  class="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                  style={{ padding: "20px" }}
                >
                  <li>
                    <button
                      className="btn btn-primary w-100 mb-3"
                      // onClick={() => {
                      //   setInvoiceData(data);
                      //   setKnow("edit");
                      // }}
                      onClick={(e) => {
                        setNewClient(data);
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#editClientModel1"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      Edit
                    </button>
                  </li>
                  <li>
                    <button
                      className="btn btn-danger w-100 mb-3"
                      // onClick={() => {
                      //   setInvoiceData(data);
                      //   setKnow("edit");
                      // }}
                      onClick={() => {
                        handle_delete_client(data._id);
                      }}
                     
                    >
                      Delete
                    </button>
                  </li>
                </ul>
              </div>{" "}
            </div>,
            row.cells[row.cells.length - 1] // Render buttons in the last cell
          );
        },
      });
      // if (showValue !== null) {
      //   tableRef.current.search(showValue).draw();
      // }
    } else {
      // If DataTable instance already exists, update data and redraw
      tableRef.current?.clear().rows.add(get_clients).draw();
    }
  }, [get_clients]);

  const [getNewClient, setNewClient] = useState({});

  const handle_add_new_client = async (e) => {
    e.preventDefault();
    await axios.post(`${API_URL}/add_new_client`, getNewClient).then((res) => {
      if (res.data.success == true) {
        // setlastData(invoiceData);

        // setInvoiceData({
        //   // ...invoiceData,
        //   inv_c_name: getNewClient.c_name,
        //   inv_c_ph_no: getNewClient.c_ph_no,
        //   inv_c_address: getNewClient.c_addr,
        // });
        // await setNewClient({});

        toast.success(`${res.data.data}`);
        // handle_get_clients();
        const form = document.querySelector("#addNewClientForm1"); // Replace with your form ID
        form.reset();

        handle_get_all_clients();

        const showHistoryModalTrigger = document.querySelector(
          `[data-bs-target="#addClientModel1"]`
        );

        showHistoryModalTrigger.click();

        // data-bs-target="#editInvoiceModel"
      } else {
        toast.error(`${res.data.data}`);
      }
    });
  };

  const handle_change_client = async (e) => {
    e.preventDefault();
    setNewClient({ ...getNewClient, [e.target.name]: e.target.value });
  };

  const handle_edit_client = async (e) => {
    e.preventDefault();
    await axios.post(`${API_URL}/edit_client`, getNewClient).then((res) => {
      if (res.data.success == true) {
        // setlastData(invoiceData);

        // setInvoiceData({
        //   // ...invoiceData,
        //   inv_c_name: getNewClient.c_name,
        //   inv_c_ph_no: getNewClient.c_ph_no,
        //   inv_c_address: getNewClient.c_addr,
        // });
        // await setNewClient({});

        toast.success(`${res.data.data}`);
        // handle_get_clients();
        // const form = document.querySelector("#editClientForm1"); // Replace with your form ID
        // form.reset();
        handle_get_all_clients();
        setNewClient({})

        const showHistoryModalTrigger = document.querySelector(
          `[data-bs-target="#editClientModel1"]`
        );

        showHistoryModalTrigger.click();

        // data-bs-target="#editInvoiceModel"
      } else {
        toast.error(`${res.data.data}`);
      }
    });
  };

  const handleResetNewClientForm = async(data)=>{
    const form = document.querySelector("#addNewClientForm1")
    form.reset()
    setNewClient({ c_name : data })
  }

  return (
    <div>
      <div
        class="offcanvas offcanvas-start"
        tabindex="-1"
        id="offcanvasWithBackdrop"
        aria-labelledby="offcanvasWithBackdropLabel"
        style={{ width: "95%" }}
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasWithBackdropLabel">
            Client Information
          </h5>

          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <button
            className="btn btn-outline-success"
            // onClick={() => setNewClient({ c_name: data.inputValue })}
            onClick={() => handleResetNewClientForm(data.inputValue)}
            data-bs-toggle="modal"
            data-bs-target="#addClientModel1"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            type="button"
          >
            Add Client
          </button>
          <div className="table1" style={{width : "100% "}}>
            <table
              id="myTable1"
              className="display table table-striped"
              style={{ width: "100% !important", textWrap: "nowrap" }}
            >
              <thead className="thead-dark">
                <tr></tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      {/* add client */}
      <div
        className="modal fade"
        id="addClientModel1"
        tabIndex="-1"
        aria-labelledby="addClientModel1"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          style={{ zIndex: "1000", maxWidth: "80vw", margin: "auto" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title fs-5"
                id="exampleModalLabel"
                style={{ fontFamily: "poppins" }}
              >
                Add New Client
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" style={{ width: "100%" }}>
              <div className="add_invoice_container_1">
                <form
                  onSubmit={(e) => handle_add_new_client(e)}
                  id="addNewClientForm1"
                >
                  <div className="row">
                    <div className="col-md-3 mb-3">
                      <TextField
                        type="text"
                        className="col-md-3 form-control custom-margin"
                        placeholder="Client Name"
                        name="c_name"
                        required
                        tabIndex="0"
                        id="outlined-basic"
                        label="Client Name"
                        variant="outlined"
                        onChange={(e) => handle_change_client(e)}
                        value={getNewClient.c_name}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <TextField
                        type="text"
                        className="col-md-3 form-control custom-margin"
                        placeholder="Address"
                        name="c_addr"
                        required
                        tabIndex="0"
                        id="outlined-basic"
                        label="Address"
                        variant="outlined"
                        onChange={(e) => handle_change_client(e)}
                        value={getNewClient.c_addr}
                        // InputLabelProps={{
                        //   shrink: true,
                        // }}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <TextField
                        type="email"
                        className="col-md-3 form-control custom-margin"
                        placeholder="Email"
                        name="c_email"
                        required
                        tabIndex="0"
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        onChange={(e) => handle_change_client(e)}
                        value={getNewClient.c_email}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <TextField
                        type="text"
                        className="col-md-3 form-control custom-margin"
                        placeholder="City"
                        name="c_city"
                        required
                        tabIndex="0"
                        id="outlined-basic"
                        label="City"
                        variant="outlined"
                        onChange={(e) => handle_change_client(e)}
                        value={getNewClient.c_city}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 mb-3">
                      <TextField
                        type="text"
                        className="col-md-3 form-control custom-margin"
                        placeholder="Country"
                        name="c_country"
                        required
                        tabIndex="0"
                        id="outlined-basic"
                        label="Country"
                        variant="outlined"
                        onChange={(e) => handle_change_client(e)}
                        value={getNewClient.c_country}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <TextField
                        type="text"
                        className="col-md-3 form-control custom-margin"
                        placeholder="GSTIN"
                        name="c_gstin"
                        tabIndex="0"
                        id="outlined-basic"
                        label="GSTIN"
                        variant="outlined"
                        onChange={(e) => handle_change_client(e)}
                        value={getNewClient.c_gstin}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <TextField
                        type="text"
                        className="col-md-3 form-control custom-margin"
                        placeholder="Phone No."
                        name="c_ph_no"
                        required
                        tabIndex="0"
                        id="outlined-basic"
                        label="Phone No."
                        variant="outlined"
                        onChange={(e) => handle_change_client(e)}
                        value={getNewClient.c_ph_no}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <TextField
                        type="text"
                        className="col-md-3 form-control custom-margin"
                        placeholder="Note"
                        name="c_note"
                        tabIndex="0"
                        id="outlined-basic"
                        label="Note"
                        variant="outlined"
                        onChange={(e) => handle_change_client(e)}
                        value={getNewClient.c_note}
                      />
                    </div>
                  </div>

                  <button type="submit" className="btn btn-outline-success">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* edit client */}

      <div
        className="modal fade"
        id="editClientModel1"
        tabIndex="-1"
        aria-labelledby="editClientModel1"
        aria-hidden="true"
      >
        <div
          className="modal-dialog"
          style={{ zIndex: "1000", maxWidth: "80vw", margin: "auto" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h1
                className="modal-title fs-5"
                id="exampleModalLabel"
                style={{ fontFamily: "poppins" }}
              >
                Edit Client
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" style={{ width: "100%" }}>
              <div className="add_invoice_container_1">
                <form
                  onSubmit={(e) => handle_edit_client(e)}
                  id="editClientForm1"
                >
                  <div className="row">
                    <div className="col-md-3 mb-3">
                      <TextField
                        type="text"
                        className="col-md-3 form-control custom-margin"
                        placeholder="Client Name"
                        name="c_name"
                        required
                        tabIndex="0"
                        id="outlined-basic"
                        label="Client Name"
                        variant="outlined"
                        onChange={(e) => handle_change_client(e)}
                        value={getNewClient.c_name}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <TextField
                        type="text"
                        className="col-md-3 form-control custom-margin"
                        placeholder="Address"
                        name="c_addr"
                        required
                        tabIndex="0"
                        id="outlined-basic"
                        label="Address"
                        variant="outlined"
                        onChange={(e) => handle_change_client(e)}
                        value={getNewClient.c_addr}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <TextField
                        type="email"
                        className="col-md-3 form-control custom-margin"
                        placeholder="Email"
                        name="c_email"
                        required
                        tabIndex="0"
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        onChange={(e) => handle_change_client(e)}
                        value={getNewClient.c_email}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <TextField
                        type="text"
                        className="col-md-3 form-control custom-margin"
                        placeholder="City"
                        name="c_city"
                        required
                        tabIndex="0"
                        id="outlined-basic"
                        label="City"
                        variant="outlined"
                        onChange={(e) => handle_change_client(e)}
                        value={getNewClient.c_city}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 mb-3">
                      <TextField
                        type="text"
                        className="col-md-3 form-control custom-margin"
                        placeholder="Country"
                        name="c_country"
                        required
                        tabIndex="0"
                        id="outlined-basic"
                        label="Country"
                        variant="outlined"
                        onChange={(e) => handle_change_client(e)}
                        value={getNewClient.c_country}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <TextField
                        type="text"
                        className="col-md-3 form-control custom-margin"
                        placeholder="GSTIN"
                        name="c_gstin"
                        tabIndex="0"
                        id="outlined-basic"
                        label="GSTIN"
                        variant="outlined"
                        onChange={(e) => handle_change_client(e)}
                        value={getNewClient.c_gstin}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <TextField
                        type="text"
                        className="col-md-3 form-control custom-margin"
                        placeholder="Phone No."
                        name="c_ph_no"
                        required
                        tabIndex="0"
                        id="outlined-basic"
                        label="Phone No."
                        variant="outlined"
                        onChange={(e) => handle_change_client(e)}
                        value={getNewClient.c_ph_no}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <TextField
                        type="text"
                        className="col-md-3 form-control custom-margin"
                        placeholder="Note"
                        name="c_note"
                        tabIndex="0"
                        id="outlined-basic"
                        label="Note"
                        variant="outlined"
                        onChange={(e) => handle_change_client(e)}
                        value={getNewClient.c_note}
                      />
                    </div>
                  </div>

                  <button type="submit" className="btn btn-outline-success">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Show_Clients;
