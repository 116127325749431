import './App.css';
import {  Routes, Route } from "react-router-dom";
import ViewInvoice from './components/Invoice/View_Invoice';
import ShowInvoice from './components/Show_Invoice/Show_Invoice';
import Login from './components/Login/Login.js';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import Line_Chart from './components/Charts/Line_Chart.js';

function App() {
if(Cookies.get('id')){
  return (
    
    <div className="App">
    
      <Routes>
      <Route path="/" element={<Login />}/>
        
           
          
      <Route path="/genrate_pdf/:ids" element={<ViewInvoice />}/>
      <Route path="/show_invoices" element={<ShowInvoice />}/>
      <Route path="/show_chart" element={<Line_Chart />}/>
      </Routes>
    </div>
  );
}
else{
  const url = new URL(window.location.href)
    const url_pathname = url.pathname
    if(url_pathname !== "/"  ){
      window.location.href = "/"
    }
  return (
    
    <div className="App">
    
      <Routes>
      <Route path="/" element={<Login />}/>
      </Routes>
    </div>
  );
}
  
 
}

export default App;
