import React, { useEffect, useRef, useState } from "react";
import "./Show_Invoice.css";
import TextField from "@mui/material/TextField";
import axios from "axios";
import API_URL from "../../Config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $, { data } from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net";
import "datatables.net-bs5"; // Import DataTables Bootstrap 5 integration CSS
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css"; //
import ReactDOM from "react-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { NumericFormat } from "react-number-format";
import Line_Chart from "../Charts/Line_Chart";
import Select from "react-select";
import Show_Clients from "../Clients/Show_Clients";
import logoImg from "./Assets/Asset 6.png";
import { FilterAlt } from "@mui/icons-material";

const ShowInvoice = () => {
  const [getRefresh, setRefresh] = useState(0);
  const [isGjChecked, setIsGjChecked] = useState(true);
  const [isInternationChecked, setInternationChecked] = useState(false);

  const [invoiceData, setInvoiceData] = useState({
    inv_no: "",
    inv_date: "",
    inv_c_name: "",
    inv_items: [
      {
        item_desc: "",
        item_price: "",
        item_qty: "",
        item_tot_price: "",
      },
    ],
    inv_subtotal: "",
    inv_cgst: "",
    inv_sgst: "",
    inv_igst: "",
    inv_igst_final: "0",
    inv_tds_final: "0",
    inv_grand_total: "",
  });

  // Function to handle changes in invoice items
  const handleItemChange = (index, e) => {
    const { name, value } = e.target;
    const updatedItems = [...invoiceData.inv_items];
    updatedItems[index][name] = value;
    setInvoiceData({ ...invoiceData, inv_items: updatedItems });
  };

  // Function to add new invoice item
  const addNewItem = () => {
    setInvoiceData({
      ...invoiceData,
      inv_items: [
        ...invoiceData.inv_items,
        { item_desc: "", item_price: "", item_qty: "" },
      ],
    });
  };

  const handle_input_change = async (e) => {
    setInvoiceData({ ...invoiceData, [e.target.name]: e.target.value });
  };

  const removeItem = (index) => {
    // Create a copy of the current invoice items array
    const updatedItems = [...invoiceData.inv_items];

    // Remove the item at the specified index
    updatedItems.splice(index, 1);

    // Calculate total price after removing the item
    let totalAllPrice = 0;
    updatedItems.forEach((item) => {
      totalAllPrice += item.item_tot_price;
    });

    // Calculate grand total, CGST, and SGST
    const grandTotal = totalAllPrice + totalAllPrice * 0.18;
    const finalCGST = totalAllPrice * 0.09;
    const finalSGST = totalAllPrice * 0.09;

    // Update the invoiceData state with the updated items and totals
    setInvoiceData({
      ...invoiceData,
      inv_items: updatedItems,
      inv_subtotal: totalAllPrice,
      inv_grand_total: grandTotal,
      inv_cgst: finalCGST,
      inv_sgst: finalSGST,
    });
  };

  const handle_get_tot_item_price = (e, index) => {
    e.preventDefault();

    // Make a copy of inv_items array from state
    const updatedItems = [...invoiceData.inv_items];

    // Retrieve the item at the specified index
    const itemToUpdate = updatedItems[index];

    // Remove commas from item_price and item_qty, then parse as integers
    const priceWithoutCommas = itemToUpdate.item_price.replace(/,/g, "");
    const qtyWithoutCommas = itemToUpdate.item_qty.replace(/,/g, "");
    // Calculate total item price
    const totalItemPrice =
      parseInt(priceWithoutCommas) * parseInt(qtyWithoutCommas);

    // Update the item's total price in the copied array
    itemToUpdate.item_tot_price = totalItemPrice.toLocaleString();

    // Update the state with the modified inv_items array
    setInvoiceData({
      ...invoiceData,
      inv_items: updatedItems,
    });
  };

  const inv_igstRef = useRef();
  const inv_gj_inputRef = useRef();
  const inv_tds_inputRef = useRef();

  let total_all_price = 0;

  const handle_get_total_sub_total = async (e) => {
    e.preventDefault();

    // Calculate total_all_price
    let total_all_price = 0;
    invoiceData.inv_items.forEach((data) => {
      // Ensure item_tot_price is a string and replace commas if present
      const priceAsString = String(data.item_tot_price);
      const priceWithoutCommas = priceAsString.replace(/,/g, "");
      total_all_price += parseFloat(priceWithoutCommas);
    });

    // Calculate grand_total, final_cgst, and final_sgst
    const grand_total = total_all_price + total_all_price * 0.18;
    const final_cgst = total_all_price * 0.09;
    const final_sgst = total_all_price * 0.09;

    // Format numbers with commas as thousand separators
    const formatted_total_price = total_all_price.toLocaleString(); // Example: 10000 => "10,000"
    const formatted_grand_total = grand_total.toLocaleString(); // Example: 11800 => "11,800"
    const formatted_final_cgst = final_cgst.toLocaleString(); // Example: 900 => "900"
    const formatted_final_sgst = final_sgst.toLocaleString(); // Example: 900 => "900"

    // Update state with formatted values
    setInvoiceData({
      ...invoiceData,
      inv_subtotal: formatted_total_price,
      inv_grand_total: formatted_grand_total,
      inv_cgst: formatted_final_cgst,
      inv_sgst: formatted_final_sgst,
    });
  };

  const handle_get_igst = async (e) => {
    if (!e.target.value) {
      e.preventDefault();
      invoiceData.inv_items.map((data) => {
        total_all_price += data.item_tot_price;
      });
      const grand_total = total_all_price + total_all_price * 0.18;
      const final_cgst = total_all_price * 0.09;
      const final_sgst = total_all_price * 0.09;
      setInvoiceData({
        ...invoiceData,
        inv_subtotal: total_all_price,
        inv_grand_total: grand_total,
        inv_cgst: final_cgst,
        inv_sgst: final_sgst,
      });
    } else {
      let updated_igst;
      let final_grand_total;
      invoiceData.inv_igst_per = e.target.value;
      updated_igst = (invoiceData.inv_subtotal * e.target.value) / 100;
      final_grand_total = invoiceData.inv_subtotal + updated_igst;
      setInvoiceData({
        ...invoiceData,
        inv_grand_total: final_grand_total,
        inv_igst_final: updated_igst,
        inv_cgst: 0,
        inv_sgst: 0,
      });
    }
  };

  const formatNumberWithCommas = (numberString) => {
    // Check if the input is a valid number string
    if (!isNaN(parseFloat(numberString))) {
      // Convert the string to a number, format it with commas, and return as string
      return parseFloat(numberString).toLocaleString();
    }
    return numberString;
  };

  const handle_check_gj = async (e) => {
    setIsGjChecked(!isGjChecked); // Toggle GJ checkbox state

    if (e.target.checked) {
      setInternationChecked(false);
      let total_all_price = 0;
      invoiceData.inv_items.forEach((data) => {
        // Ensure item_tot_price is a string and replace commas if present
        const priceAsString = String(data.item_tot_price);
        const priceWithoutCommas = priceAsString.replace(/,/g, "");
        total_all_price += parseFloat(priceWithoutCommas);
      });

      const grand_total = total_all_price + total_all_price * 0.18;
      const final_cgst = total_all_price * 0.09;
      const final_sgst = total_all_price * 0.09;

      setInvoiceData({
        ...invoiceData,
        inv_subtotal: formatNumberWithCommas(total_all_price.toString()), // Format subtotal with commas
        inv_grand_total: formatNumberWithCommas(grand_total.toString()), // Format grand total with commas
        inv_cgst: formatNumberWithCommas(final_cgst.toString()), // Format cgst with commas
        inv_sgst: formatNumberWithCommas(final_sgst.toString()), // Format sgst with commas
        inv_igst_final: "0", // Reset igst_final to '0'
      });
    } else {
      const updated_igst =
        parseFloat(invoiceData.inv_subtotal.replace(/,/g, "")) * 0.18; // Remove commas if present and calculate igst
      const final_grand_total =
        parseFloat(invoiceData.inv_subtotal.replace(/,/g, "")) + updated_igst; // Remove commas if present and calculate grand total

      setInvoiceData({
        ...invoiceData,
        inv_grand_total: formatNumberWithCommas(final_grand_total.toString()), // Format grand total with commas
        inv_igst_final: formatNumberWithCommas(updated_igst.toString()), // Format igst_final with commas
        inv_cgst: "0", // Reset cgst to '0' as per your logic
        inv_sgst: "0", // Reset sgst to '0' as per your logic
      });
    }
  };

  const handle_check_tds = async (e) => {
    if (e.target.checked == true) {
      invoiceData.inv_items.map((data) => {
        total_all_price += data.item_tot_price;
      });

      if (inv_gj_inputRef.current.checked == true) {
        const grand_total_1 = total_all_price - total_all_price * 0.1;
        const final_cgst = grand_total_1 * 0.09;
        const final_sgst = grand_total_1 * 0.09;
        const grand_total = grand_total_1 + final_cgst + final_sgst;
        setInvoiceData({
          ...invoiceData,
          inv_subtotal: total_all_price,
          inv_grand_total: grand_total,
          inv_cgst: final_cgst,
          inv_sgst: final_sgst,
          inv_tds_final: grand_total_1,
          inv_igst_final: 0,
        });
      } else {
        const grand_total_1 = total_all_price - total_all_price * 0.1;
        const updated_igst = grand_total_1 * 0.18;
        const grand_total = grand_total_1 + updated_igst;
        setInvoiceData({
          ...invoiceData,
          inv_subtotal: total_all_price,
          inv_grand_total: grand_total,
          inv_cgst: 0,
          inv_sgst: 0,
          inv_tds_final: grand_total_1,
          inv_igst_final: 0,
        });
      }
    } else {
      invoiceData.inv_items.map((data) => {
        total_all_price += data.item_tot_price;
      });

      const grand_total = total_all_price + total_all_price * 0.18;
      const final_cgst = total_all_price * 0.09;
      const final_sgst = total_all_price * 0.09;
      setInvoiceData({
        ...invoiceData,
        inv_subtotal: total_all_price,
        inv_grand_total: grand_total,
        inv_cgst: final_cgst,
        inv_sgst: final_sgst,
        inv_tds_final: 0,
        inv_igst_final: 0,
      });
    }
  };

  const handle_submit = async (e) => {
    e.preventDefault();
    await axios.post(`${API_URL}/add_new_invoice`, invoiceData).then((res) => {
      if (res.data.success == true) {
        toast.success(`${res.data.data}`);
        setInvoiceData({
          inv_no: "",
          inv_date: "",
          inv_c_name: "",
          inv_items: [
            {
              item_desc: "",
              item_price: "",
              item_qty: "",
              item_tot_price: "",
            },
          ],
          inv_subtotal: "",
          inv_cgst: "",
          inv_sgst: "",
          inv_igst: "",
          inv_igst_final: "0",
          inv_tds_final: "0",
          inv_grand_total: "",
        });
        handle_get_all_invoice_data();
        setRefresh(new Date());
        const showHistoryModalTrigger = document.querySelector(
          `[data-bs-target="#showHistoryModal"]`
        );
        showHistoryModalTrigger.click();
      } else {
        toast.error(`${res.data.data}`);
      }
    });
  };

  const tableRef = useRef(null);

  const [get_all_invoices, set_all_invoices] = useState([]);

  const handle_get_all_invoice_data = async () => {
    await axios.post(`${API_URL}/get_all_invoice`).then((res) => {
      if (res.data.success == true) {
        set_all_invoices(res.data.data);
      } else {
        toast.error(`${res.data.data}`);
      }
    });
  };

  useEffect(() => {
    handle_get_all_invoice_data();
  }, [set_all_invoices]);

  const navigate = useNavigate();

  const [getEditedData, setEditedData] = useState([]);

  const handle_edit_invoice_status = async (e, _id) => {
    e.preventDefault();
    await axios
      .post(`${API_URL}/edit_invoice_status`, {
        id: _id,
        status: e.target.value,
      })
      .then((res) => {
        if (res.data.success == true) {
          toast.success(`${res.data.data}`);
          setRefresh(new Date());
          handle_get_all_invoice_data();
        } else {
          toast.error(`${res.data.data}`);
        }
      });
  };

  const handle_delete_invoice = async (id) => {
    const ans = window.confirm("Are You Sure...");
    if (ans) {
      await axios.post(`${API_URL}/delete_invoice`, { id: id }).then((res) => {
        if (res.data.success == true) {
          toast.success(`${res.data.data}`);
          handle_get_all_invoice_data();
          setRefresh(new Date());
        } else {
          toast.success(`${res.data.data}`);
        }
      });
    }
  };

  const handleGetDataForEdit = async (data) => {
    await setInvoiceData(data);
    if (data.inv_igst_final !== "0") {
      setIsGjChecked(false);
      setInternationChecked(false);
    } else if (data.inv_cgst !== "0") {
      setIsGjChecked(true);
      setInternationChecked(false);
    } else if (data.inv_cgst == "0" && data.inv_igst_final == "0") {
      setIsGjChecked(false);
      setInternationChecked(true);
    }
  };

  useEffect(() => {
    $(document).ready(() => {
      $(".pending_btn").css({ "background-color": "grey", color: "white" });
      $(".paid_btn").click(() => {
        $(".pending_btn").css({ "background-color": "white", color: "black" });
        $(".paid_btn").css({ "background-color": "#198754", color: "white" });
      });
      $(".pending_btn").click(() => {
        $(".paid_btn").css({ "background-color": "white", color: "green" });
        $(".pending_btn").css({ "background-color": "grey", color: "white" });
      });
    });
  }, []);

  // data table

  useEffect(() => {
    if (get_all_invoices.length > 0 && !tableRef.current) {
      tableRef.current = $("#myTable").DataTable({
        // responsive: true,
        data: get_all_invoices,
        columns: [
          {
            title: "No.",
            data: null,
            render: function (data, type, row, meta) {
              return `<div>${meta.row + 1}</div>`;
            },
            width: "5px",
          },
          { title: "Invoice No", data: "inv_no", width: "10px" },
          {
            title: "Invoice Date",
            data: "inv_date",
            width: "50px",
            render: function (data, type, row) {
              // Check if data is null
              if (!data) {
                return "-";
              }

              // Format the date as dd-mm-yyyy
              const date = new Date(data);
              const formattedDate = `${date
                .getDate()
                .toString()
                .padStart(2, "0")}-${(date.getMonth() + 1)
                .toString()
                .padStart(2, "0")}-${date.getFullYear()}`;

              // return formattedDate;
              return `<div style="width : 100px ; text-wrap : no-wrap;">${formattedDate}</div>`;
            },
          },
          { title: "Client Name", data: "inv_c_name", width: "400px" },
          { title: "Grand Total", data: "inv_grand_total", width: "400px" },
          { title: "Status", data: "status", width: "100px" },
          { title: "Actions", data: null, width: "1px" },
        ],

        createdRow: function (row, data, dataIndex) {
          ReactDOM.render(
            <div style={{ display: "flex", gap: "10px" }}>
              <div class="dropdown">
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column-reverse",
                  }}
                >
                  <MoreHorizIcon />
                </button>
                <ul
                  class="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                  style={{ padding: "20px" }}
                >
                  <li>
                    <button
                      className="btn btn-success w-100 mb-3"
                      // onClick={(e) => set_orderInfo(subAdminData[dataIndex])}
                      // onClick={(e) => set_orderInfo(subAdminData[dataIndex])}
                      // data-bs-toggle="modal"
                      // data-bs-target="#showImagesModal"
                      onClick={() =>
                        navigate(
                          `/genrate_pdf/${get_all_invoices[dataIndex]._id}`
                        )
                      }
                    >
                      View
                    </button>
                  </li>
                  <li></li>
                  {/* <li>
                    <button
                      className="btn btn-info w-100 mb-3"
                      onClick={(e) => {
                        generatePDF2(subAdminData[dataIndex]);
                        e.preventDefault(); // Prevent any default behavior, like submitting a form
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#showImagesModal"
                    >
                      Pdf
                    </button>
                  </li> */}
                  <li>
                    <button
                      className="btn btn-primary w-100 mb-3"
                      onClick={() => {
                        handleGetDataForEdit(data);
                        setKnow("edit");
                      }}
                      // onClick={(e) => {set_order_price(subAdminData[dataIndex]);set_updated_order_price(subAdminData[dataIndex].price)}}
                      data-bs-toggle="modal"
                      data-bs-target="#editInvoiceModel"
                    >
                      Edit
                    </button>
                  </li>
                  <li>
                    <button
                      className="btn btn-danger w-100 mb-3"
                      onClick={() => handle_delete_invoice(data._id)}
                      // onClick={(e) => {set_order_price(subAdminData[dataIndex]);set_updated_order_price(subAdminData[dataIndex].price)}}
                    >
                      Delete
                    </button>
                  </li>
                </ul>
              </div>{" "}
            </div>,
            row.cells[row.cells.length - 1] // Render buttons in the last cell
          );
          ReactDOM.render(
            <div style={{ display: "flex", gap: "10px" }}>
              <select
                className="form-control"
                onChange={(e) => handle_edit_invoice_status(e, data._id)}
                style={{
                  backgroundColor:
                    data.status == "paid" ? "#f6fff2" : "#FFF2F2",
                  color: data.status == "paid" ? "#299927" : "#C23D3D",
                  border:
                    data.status == "paid"
                      ? "2px solid #299927"
                      : "2px solid #C23D3D",
                }}
              >
                <option
                  value="pending"
                  selected={data.status == "pending" ? true : false}
                >
                  Pending
                </option>
                <option
                  value="paid"
                  selected={data.status == "paid" ? true : false}
                >
                  Paid
                </option>
              </select>
            </div>,
            row.cells[row.cells.length - 2] // Render buttons in the last cell
          );
        },
      });

      tableRef.current.column(5).search("").draw();
    } else {
      tableRef.current?.clear().rows.add(get_all_invoices).draw();
    }
  }, [get_all_invoices]);

  const handle_EditInvoice = async (e) => {
    e.preventDefault();
    await axios.post(`${API_URL}/edit_invoice`, invoiceData).then((res) => {
      if (res.data.success == true) {
        toast.success(`${res.data.data}`);

        setRefresh(new Date());

        handle_get_all_invoice_data();

        const showHistoryModalTrigger = document.querySelector(
          `[data-bs-target="#editInvoiceModel"]`
        );

        showHistoryModalTrigger.click();
      } else {
        toast.error(`${res.data.data}`);
      }
    });
  };

  const handle_logout = async () => {
    Cookies.remove("id");
    window.location.href = "/";
  };

  const [getOptions, setOptions] = useState([]);

  const handle_get_clients = async () => {
    await axios.post(`${API_URL}/get_clients`).then((res) => {
      if (res.data.success == true) {
        const newOptions = res.data.data.map((data) => ({
          value: data.c_name,
          label: data.c_name,
        }));
        const uniqueOptions = [...getOptions, ...newOptions].filter(
          (option, index, self) =>
            self.findIndex((o) => o.value === option.value) === index
        );
        setOptions(uniqueOptions);
      }
    });
  };

  useEffect(() => {
    handle_get_clients();
  }, [getRefresh]);

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "trawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const [getClientValue, setClientValue] = useState("");

  const NoOptionsMessage = (data) => (
    <div>
      No options available. {data.inputValue}
      <button
        className="btn btn-outline-success"
        onClick={() => setNewClient({ c_name: data.inputValue })}
        data-bs-toggle="modal"
        data-bs-target="#addClientModel"
        data-bs-dismiss="modal"
        aria-label="Close"
        type="button"
      >
        Add Client
      </button>
    </div>
  );

  const [getNewClient, setNewClient] = useState({});

  const handle_change_client = async (e) => {
    e.preventDefault();
    setNewClient({ ...getNewClient, [e.target.name]: e.target.value });
  };

  const [lastData, setlastData] = useState([]);

  const handle_add_new_client = async (e) => {
    e.preventDefault();
    await axios.post(`${API_URL}/add_new_client`, getNewClient).then((res) => {
      if (res.data.success == true) {
        toast.success(`${res.data.data}`);
        handle_get_clients();
        setRefresh(new Date());

        const form = document.querySelector("#addNewClientForm"); // Replace with your form ID
        form.reset(); // Reset the form data
        const closeButton = document.querySelector('[data-bs-dismiss="modal"]');
        closeButton.click();
        if (getKnow == "add") {
          const showHistoryModalTrigger = document.querySelector(
            `[data-bs-target="#showHistoryModal"]`
          );

          showHistoryModalTrigger.click();
          setInvoiceData({
            ...invoiceData,
            inv_c_name: getNewClient.c_name,
            inv_c_ph_no: getNewClient.c_ph_no,
            inv_c_address: getNewClient.c_addr,
          });
        }
        if (getKnow == "edit") {
          const showHistoryModalTrigger1 = document.querySelector(
            `[data-bs-target="#editInvoiceModel"]`
          );
          showHistoryModalTrigger1.click();
          setInvoiceData({
            ...invoiceData,
            inv_c_name: getNewClient.c_name,
            inv_c_ph_no: getNewClient.c_ph_no,
            inv_c_address: getNewClient.c_addr,
          });
        }
        // data-bs-target="#editInvoiceModel"
      } else {
        toast.error(`${res.data.data}`);
      }
    });
  };

  const [getKnow, setKnow] = useState("");

  const handle_check_international = async (e) => {
    setInternationChecked(!isInternationChecked);
    if (e.target.checked == true) {
      setInvoiceData({
        ...invoiceData,
        inv_cgst: "0",
        inv_sgst: "0",
        inv_igst_final: "0",
        inv_grand_total: invoiceData.inv_subtotal,
      });
      setIsGjChecked(false); // Uncheck GJ checkbox using state
    } else {
      setIsGjChecked(true); // Uncheck GJ checkbox using state
      let total_all_price = 0;
      invoiceData.inv_items.forEach((data) => {
        // Ensure item_tot_price is a string and replace commas if present
        const priceAsString = String(data.item_tot_price);
        const priceWithoutCommas = priceAsString.replace(/,/g, "");
        total_all_price += parseFloat(priceWithoutCommas);
      });

      const grand_total = total_all_price + total_all_price * 0.18;
      const final_cgst = total_all_price * 0.09;
      const final_sgst = total_all_price * 0.09;

      setInvoiceData({
        ...invoiceData,
        inv_subtotal: formatNumberWithCommas(total_all_price.toString()), // Format subtotal with commas
        inv_grand_total: formatNumberWithCommas(grand_total.toString()), // Format grand total with commas
        inv_cgst: formatNumberWithCommas(final_cgst.toString()), // Format cgst with commas
        inv_sgst: formatNumberWithCommas(final_sgst.toString()), // Format sgst with commas
        inv_igst_final: "0", // Reset igst_final to '0'
      });
    }
  };

  const handle_addInvoice_click = () => {
    setInvoiceData({
      inv_no: "",
      inv_date: "",
      inv_c_name: "",
      inv_items: [
        {
          item_desc: "",
          item_price: "",
          item_qty: "",
          item_tot_price: "",
        },
      ],
      inv_subtotal: "",
      inv_cgst: "",
      inv_sgst: "",
      inv_igst: "",
      inv_igst_final: "0",
      inv_tds_final: "0",
      inv_grand_total: "",
    });
  };

  const [getChangePass, setChangePass] = useState({});

  const handle_change_pass = async (e) => {
    e.preventDefault();
    getChangePass.id = Cookies.get("id");
    await axios
      .post(`${API_URL}/admin_change_password`, getChangePass)
      .then((res) => {
        if (res.data.success == true) {
          toast.success(`${res.data.data}`);
        } else {
          toast.error(`${res.data.data}`);
        }
      });
  };

  const [get_filterStatus, set_filterStatus] = useState("All Invoice");
  const removeDropdownShowRef = useRef(null)

  const close_modelClickOnPendingInvoices = async()=>{
    // const removeShow = document.querySelector("dropdown-menu").classList
    // console.log(removeShow)
    removeDropdownShowRef.current.classList.remove("show")
  }

  return (
    <div style={{ fontFamily: "poppins" }}>
      <ToastContainer />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <button
          className="btn addNewInvoicesBtn mt-3"
          data-bs-toggle="modal"
          data-bs-target="#showHistoryModal"
          style={{ marginLeft: "2%" }}
          onClick={() => {
            setKnow("add");
            setRefresh(new Date());
            handle_addInvoice_click();
          }}
        >
          Add New Invoice
        </button>
        <button
          className="btn btn-outline-primary mt-3"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasWithBackdrop"
          aria-controls="offcanvasWithBackdrop"
        >
          Client Information
        </button>
        {/* <button
          className="btn btn-outline-danger mt-3 "
          data-bs-toggle="modal"
          data-bs-target="#showHistoryModal"
          style={{ marginRight: "2%" }}
          onClick={() => handle_logout()}
        >
          Logout
        </button> */}

        <div className="navdropdownbtn" style={{ marginRight: "2%" }}>
          <div class="dropdown" style={{ marginRight: "2%" }}>
            <button class="dropbtn">
              <img
                src={logoImg}
                alt=""
                style={{ width: "20px", height: "auto" }}
              />
            </button>
            <div class="dropdown-content">
              <button
                className="btn btn-outline-danger mt-2 w-100"
                style={{ marginRight: "2%" }}
                onClick={() => handle_logout()}
              >
                Logout
              </button>
              <button
                className="btn btn-outline-primary mt-2 w-100"
                data-bs-toggle="modal"
                data-bs-target="#changePasswordModel"
                style={{ marginRight: "2%" }}
              >
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>
      <Show_Clients data={getRefresh} />

      <button
        className="btn btn-primary w-100 mb-3"
        onClick={() => {
          setInvoiceData(data);
          setKnow("edit");
        }}
        data-bs-toggle="modal"
        data-bs-target="#editInvoiceModel"
        style={{ display: "none" }}
      >
        Edit
      </button>
      <div
        style={{
          textAlign: "start",
          marginLeft: "2%",
          display: "flex",
          gap: "10px",
        }}
        className="mt-4"
      >
        {/* <button
          className="btn btn-outline-success paid_btn"
          onClick={(e) => {
            tableRef.current?.column(5).search("Paid").draw();
          }}
        >
          Paid
        </button>
        <button
          className="btn btn-outline-secondary pending_btn"
          onClick={(e) => {
            tableRef.current?.column(5).search("Pending").draw();
          }}
        >
          Pending
        </button> */}
        {/* <div class="dropdown">
          <button
            // class="btn btn-secondary dropdown-toggle"
            className={
              get_filterStatus === "Paid Invoice"
                ? "btn btn-success dropdown-toggle"
                : get_filterStatus === "All Invoice"
                ? "btn btn-success dropdown-toggle"
                : get_filterStatus === "Pending Invoice" ? "btn btn-danger dropdown-toggle" : ""
            }
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FilterAlt /> {get_filterStatus}
          </button>
          <ul
            class="dropdown-menu"
            aria-labelledby="dropdownMenuButton1"
            style={{ padding: "20px" }}
          >
            <li>
              <button
                className="btn btn-success w-100 mb-3"
                // onClick={(e) => set_orderInfo(subAdminData[dataIndex])}
                // onClick={(e) => set_orderInfo(subAdminData[dataIndex])}
                // data-bs-toggle="modal"
                // data-bs-target="#showImagesModal"
                onClick={() => {
                  tableRef.current?.column(5).search("").draw();
                  set_filterStatus("All Invoice");
                }}
              >
                All Invoice
              </button>
            </li>
            
            <li>
              <button
                className="btn btn-outline-success w-100 mb-3"
                // onClick={(e) => set_orderInfo(subAdminData[dataIndex])}
                // onClick={(e) => set_orderInfo(subAdminData[dataIndex])}
                // data-bs-toggle="modal"
                // data-bs-target="#showImagesModal"
                onClick={() => {
                  tableRef.current?.column(5).search("Paid").draw();
                  set_filterStatus("Paid Invoice");
                }}
              >
                Paid Invoice
              </button>
            </li>
            <li>
              <button
                className="btn btn-outline-danger w-100 mb-3"
                // onClick={(e) => set_orderInfo(subAdminData[dataIndex])}
                // onClick={(e) => set_orderInfo(subAdminData[dataIndex])}
                // data-bs-toggle="modal"
                // data-bs-target="#showImagesModal"
                onClick={() => {
                  tableRef.current?.column(5).search("Pending").draw();
                  set_filterStatus("Pending Invoice");
                }}
              >
                Pending Invoice
              </button>
            </li>
          </ul>
        </div>{" "} */}

        <div className="dropdown">
          <button
            className={`btn dropdown-toggle ${
              get_filterStatus === "Paid Invoice" ||
              get_filterStatus === "All Invoice"
                ? "btn-success"
                : get_filterStatus === "Pending Invoice"
                ? "btn-danger"
                : "btn-secondary"
            }`}
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FilterAlt /> {get_filterStatus}
          </button>
          <ul
            className="dropdown-menu"
            aria-labelledby="dropdownMenuButton1"
            style={{ padding: "20px" }}
            ref={removeDropdownShowRef}
          >
            <li>
              <button
                className="btn btn-success w-100 mb-3"
                onClick={() => {
                  tableRef.current?.column(5).search("").draw();
                  set_filterStatus("All Invoice");
                  close_modelClickOnPendingInvoices()

                }}
              >
                All Invoice
              </button>
            </li>

            <li>
              <button
                className="btn btn-outline-success w-100 mb-3"
                onClick={() => {
                  tableRef.current?.column(5).search("Paid").draw();
                  set_filterStatus("Paid Invoice");
                  close_modelClickOnPendingInvoices()

                }}
              >
                Paid Invoice
              </button>
            </li>
            <li>
              <button
                className="btn btn-outline-danger w-100 mb-3"
                onClick={() => {
                  tableRef.current?.column(5).search("Pending").draw();
                  set_filterStatus("Pending Invoice");
                  close_modelClickOnPendingInvoices()
                }}
              >
                Pending Invoice
              </button>
            </li>
          </ul>
        </div>
      </div>
      <center>
        <div className="table1">
          <table
            id="myTable"
            className="display table table-striped"
            style={{ width: "100%", textWrap: "nowrap" }}
          >
            <thead className="thead-dark">
              <tr></tr>
            </thead>
          </table>
        </div>

        <div
          className="modal fade"
          id="changePasswordModel"
          tabIndex="-1"
          aria-labelledby="changePasswordModel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            style={{ zIndex: "1000", maxWidth: "30vw", margin: "auto" }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h1
                  className="modal-title fs-5"
                  id="exampleModalLabel"
                  style={{ fontFamily: "poppins" }}
                >
                  Change Password
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body" style={{ width: "100%" }}>
                <div className="add_invoice_container_1">
                  <form
                    onSubmit={(e) => handle_change_pass(e)}
                    id="addNewClientForm"
                  >
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <TextField
                          type="text"
                          className="col-md-3 form-control custom-margin"
                          placeholder="Old Password"
                          name="c_name"
                          required
                          tabIndex="0"
                          id="outlined-basic"
                          label="Old Password"
                          variant="outlined"
                          onChange={(e) =>
                            setChangePass({
                              ...getChangePass,
                              oldPass: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      <div className="col-md-12 mb-3">
                        <TextField
                          type="text"
                          className="col-md-3 form-control custom-margin"
                          placeholder="New Password"
                          name="c_addr"
                          required
                          tabIndex="0"
                          id="outlined-basic"
                          label="New Password"
                          variant="outlined"
                          onChange={(e) =>
                            setChangePass({
                              ...getChangePass,
                              newPass: e.target.value,
                            })
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="btn btn-outline-success"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="addClientModel"
          tabIndex="-1"
          aria-labelledby="addClientModel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            style={{ zIndex: "1000", maxWidth: "80vw", margin: "auto" }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h1
                  className="modal-title fs-5"
                  id="exampleModalLabel"
                  style={{ fontFamily: "poppins" }}
                >
                  Add New Client
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body" style={{ width: "100%" }}>
                <div className="add_invoice_container_1">
                  <form
                    onSubmit={(e) => handle_add_new_client(e)}
                    id="addNewClientForm"
                  >
                    <div className="row">
                      <div className="col-md-3 mb-3">
                        <TextField
                          type="text"
                          className="col-md-3 form-control custom-margin"
                          placeholder="Client Name"
                          name="c_name"
                          required
                          tabIndex="0"
                          id="outlined-basic"
                          label="Client Name"
                          variant="outlined"
                          onChange={(e) => handle_change_client(e)}
                          value={getNewClient.c_name}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <TextField
                          type="text"
                          className="col-md-3 form-control custom-margin"
                          placeholder="Address"
                          name="c_addr"
                          required
                          tabIndex="0"
                          id="outlined-basic"
                          label="Address"
                          variant="outlined"
                          onChange={(e) => handle_change_client(e)}
                          value={getNewClient.c_addr}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <TextField
                          type="email"
                          className="col-md-3 form-control custom-margin"
                          placeholder="Email"
                          name="c_email"
                          required
                          tabIndex="0"
                          id="outlined-basic"
                          label="Email"
                          variant="outlined"
                          onChange={(e) => handle_change_client(e)}
                          value={getNewClient.c_email}
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <TextField
                          type="text"
                          className="col-md-3 form-control custom-margin"
                          placeholder="City"
                          name="c_city"
                          required
                          tabIndex="0"
                          id="outlined-basic"
                          label="City"
                          variant="outlined"
                          onChange={(e) => handle_change_client(e)}
                          value={getNewClient.c_city}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3 mb-3">
                        <TextField
                          type="text"
                          className="col-md-3 form-control custom-margin"
                          placeholder="Country"
                          name="c_country"
                          required
                          tabIndex="0"
                          id="outlined-basic"
                          label="Country"
                          variant="outlined"
                          onChange={(e) => handle_change_client(e)}
                          value={getNewClient.c_country}
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <TextField
                          type="text"
                          className="col-md-3 form-control custom-margin"
                          placeholder="GSTIN"
                          name="c_gstin"
                          required
                          tabIndex="0"
                          id="outlined-basic"
                          label="GSTIN"
                          variant="outlined"
                          onChange={(e) => handle_change_client(e)}
                          value={getNewClient.c_gstin}
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <TextField
                          type="text"
                          className="col-md-3 form-control custom-margin"
                          placeholder="Phone No."
                          name="c_ph_no"
                          required
                          tabIndex="0"
                          id="outlined-basic"
                          label="Phone No."
                          variant="outlined"
                          onChange={(e) => handle_change_client(e)}
                          value={getNewClient.c_ph_no}
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <TextField
                          type="text"
                          className="col-md-3 form-control custom-margin"
                          placeholder="Note"
                          name="c_note"
                          required
                          tabIndex="0"
                          id="outlined-basic"
                          label="Note"
                          variant="outlined"
                          onChange={(e) => handle_change_client(e)}
                          value={getNewClient.c_note}
                        />
                      </div>
                    </div>

                    <button
                      type="submit"
                      className="btn btn-outline-success"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="showHistoryModal"
          tabIndex="-1"
          aria-labelledby="showHistoryModal"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            style={{ zIndex: "1000", maxWidth: "80vw", margin: "auto" }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h1
                  className="modal-title fs-5"
                  id="exampleModalLabel"
                  style={{ fontFamily: "poppins" }}
                >
                  Add Invoice
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body" style={{ width: "100%" }}>
                <div className="add_invoice_container_1">
                  <form onSubmit={(e) => handle_submit(e)} id="addInvoice12">
                    <div className="row">
                      <div className="col-md-4 mb-3">
                        <TextField
                          type="text"
                          className="col-md-4 form-control custom-margin"
                          placeholder="Invoice No"
                          name="inv_no"
                          required
                          tabIndex="0"
                          id="outlined-basic"
                          label="Invoice No"
                          variant="outlined"
                          onChange={(e) => handle_input_change(e)}
                          value={invoiceData.inv_no}
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <Select
                          options={getOptions}
                          value={getOptions.find(
                            (option) => option.value === invoiceData.inv_c_name
                          )}
                          // isLoading={getOptions.length === 0}
                          noOptionsMessage={(inputValue) =>
                            NoOptionsMessage(inputValue)
                          }
                          onChange={(option) =>
                            setInvoiceData({
                              ...invoiceData,
                              inv_c_name: option.value,
                            })
                          }
                          // onInputChange={(inputValue)=>setClientValue(inputValue)}
                        />
                      </div>

                      <div className="col-md-4 mb-3">
                        <input
                          type="date"
                          name="inv_date"
                          className="col-md-4 form-control custom-margin"
                          style={{ height: "56px" }}
                          onChange={(e) => handle_input_change(e)}
                          value={invoiceData.inv_date}
                        />
                      </div>
                    </div>

                    {/* Render invoice items */}
                    {invoiceData.inv_items &&
                      invoiceData.inv_items.map((item, index) => (
                        <div className="row" key={index}>
                          <div className="col-md-3">
                            <TextField
                              type="text"
                              className="col-md-3 mb-3 form-control custom-margin"
                              placeholder="Item Description"
                              name="item_desc"
                              value={item.item_desc}
                              onChange={(e) => handleItemChange(index, e)}
                              required
                              tabIndex="0"
                              id="outlined-basic"
                              label="Item Description"
                              variant="outlined"
                            />
                          </div>
                          <div className="col-md-3">
                            <NumericFormat
                              className="col-md-3 mb-3 form-control custom-margin"
                              placeholder="Price"
                              name="item_price"
                              value={item.item_price}
                              onChange={(e) => {
                                handleItemChange(index, e);
                                handle_get_tot_item_price(e, index);
                                handle_get_total_sub_total(e);
                              }}
                              required
                              tabIndex="0"
                              id="outlined-basic"
                              label="Price"
                              variant="outlined"
                              thousandSeparator=","
                              allowLeadingZeros
                              style={{ height: "56px" }}
                            />
                          </div>
                          <div className="col-md-3">
                            <TextField
                              type="number"
                              className="col-md-3 mb-3 form-control custom-margin"
                              placeholder="QTY"
                              name="item_qty"
                              value={item.item_qty}
                              onChange={(e) => {
                                handleItemChange(index, e);
                                handle_get_tot_item_price(e, index);
                                handle_get_total_sub_total(e);
                              }}
                              required
                              tabIndex="0"
                              id="outlined-basic"
                              label="QTY"
                              variant="outlined"
                            />
                          </div>
                          <div className="col-md-3">
                            <TextField
                              type="text"
                              // className={index == invoiceData.inv_items.length-1 ? "col-md-9 mb-3 custom-margin": "col-md-12 mb-3 custom-margin"}
                              className="col-md-12 mb-3 custom-margin"
                              placeholder="Price"
                              name="item_tot_price"
                              value={item.item_tot_price}
                              onChange={(e) => handleItemChange(index, e)}
                              required
                              tabIndex="0"
                              id="outlined-basic"
                              label="Price"
                              variant="outlined"
                              style={{ marginRight: "10px" }}
                              disabled
                              InputLabelProps={{ shrink: true }} // Set the shrink property to true
                            />
                          </div>
                          <div
                            className="row mb-3"
                            style={{ justifyContent: "end" }}
                          >
                            {index == invoiceData.inv_items.length - 1 ? (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-primary col-md-1 "
                                  onClick={addNewItem}
                                  style={{ marginRight: "10px" }}
                                >
                                  +
                                </button>
                                {index !== 0 ? (
                                  <button
                                    type="button"
                                    className="btn btn-primary col-md-1"
                                    onClick={() => removeItem(index)}
                                  >
                                    x
                                  </button>
                                ) : null}
                              </>
                            ) : null}
                          </div>
                        </div>
                      ))}
                    <div
                      className=""
                      style={{
                        display: "flex",
                        justifyContent: "start",
                      }}
                    >
                      <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "30%",
                        }}
                      >
                        <label style={{ fontSize: "1.2em" }}>
                          <input
                            type="checkbox"
                            ref={inv_gj_inputRef}
                            onClick={(e) => handle_check_gj(e)}
                            checked={isGjChecked}
                          />{" "}
                          GJ
                        </label>

                        <label style={{ fontSize: "1.2em" }}>
                          <input
                            type="checkbox"
                            ref={inv_tds_inputRef}
                            onClick={(e) => handle_check_international(e)}
                            checked={isInternationChecked}
                          />{" "}
                          International
                        </label>
                      </div>
                    </div>
                    <div className="grand_total_txt1">
                      Sub Total &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;:-{" "}
                      {invoiceData.inv_subtotal}
                    </div>

                    {invoiceData.inv_sgst !== "0" ? (
                      <>
                        {" "}
                        <div className="grand_total_txt1">
                          SGST (9%) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:-{" "}
                          {invoiceData.inv_sgst}
                        </div>
                        <div className="grand_total_txt1">
                          CGST (9%) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:-{" "}
                          {invoiceData.inv_cgst}
                        </div>
                      </>
                    ) : null}
                    {invoiceData.inv_igst_final !== "0" &&
                    invoiceData.inv_igst_final !== null ? (
                      <div className="grand_total_txt1">
                        IGST (18%)
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:-{" "}
                        {invoiceData.inv_igst_final}
                      </div>
                    ) : null}
                    <div className="grand_total_txt1">
                      Grand Total &nbsp;&nbsp;&nbsp;:-{" "}
                      {invoiceData.inv_grand_total}
                    </div>
                    <button type="submit" className="btn btn-outline-success">
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="editInvoiceModel"
          tabIndex="-1"
          aria-labelledby="editInvoiceModel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            style={{ zIndex: "1000", maxWidth: "80vw", margin: "auto" }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h1
                  className="modal-title fs-5"
                  id="exampleModalLabel"
                  style={{ fontFamily: "poppins" }}
                >
                  Edit Invoice
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body" style={{ width: "100%" }}>
                <div className="add_invoice_container_1">
                  <form onSubmit={(e) => handle_EditInvoice(e)}>
                    <div className="row">
                      <div className="col-md-4 mb-3">
                        <TextField
                          type="text"
                          className="col-md-3 form-control custom-margin"
                          placeholder="Invoice No"
                          name="inv_no"
                          required
                          tabIndex="0"
                          id="outlined-basic"
                          label="Invoice No"
                          variant="outlined"
                          onChange={(e) => handle_input_change(e)}
                          value={invoiceData.inv_no}
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <Select
                          options={getOptions}
                          value={getOptions.find(
                            (option) => option.value === invoiceData.inv_c_name
                          )}
                          noOptionsMessage={(inputValue) =>
                            NoOptionsMessage(inputValue)
                          }
                          onChange={(option) =>
                            setInvoiceData({
                              ...invoiceData,
                              inv_c_name: option.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-md-4 mb-3">
                        <input
                          type="date"
                          name="inv_date"
                          className="col-md-4 form-control custom-margin"
                          style={{ height: "56px" }}
                          onChange={(e) => handle_input_change(e)}
                          // value={new Date(invoiceData.inv_date)}
                          value={
                            invoiceData.inv_date
                              ? new Date(invoiceData.inv_date)
                                  .toISOString()
                                  .split("T")[0]
                              : null
                          }
                        />
                      </div>
                    </div>

                    {/* Render invoice items */}
                    {invoiceData.inv_items &&
                      invoiceData.inv_items.map((item, index) => (
                        <div className="row" key={index}>
                          <div className="col-md-3">
                            <TextField
                              type="text"
                              className="col-md-3 mb-3 form-control custom-margin"
                              placeholder="Item Description"
                              name="item_desc"
                              value={item.item_desc}
                              onChange={(e) => handleItemChange(index, e)}
                              required
                              tabIndex="0"
                              id="outlined-basic"
                              label="Item Description"
                              variant="outlined"
                            />
                          </div>
                          <div className="col-md-3">
                            <NumericFormat
                              className="col-md-3 mb-3 form-control custom-margin"
                              placeholder="Price"
                              name="item_price"
                              value={item.item_price}
                              onChange={(e) => {
                                handleItemChange(index, e);
                                handle_get_tot_item_price(e, index);
                                handle_get_total_sub_total(e);
                              }}
                              required
                              tabIndex="0"
                              id="outlined-basic"
                              label="Price"
                              variant="outlined"
                              thousandSeparator=","
                              allowLeadingZeros
                              style={{ height: "56px" }}
                            />
                          </div>
                          <div className="col-md-3">
                            <TextField
                              type="number"
                              className="col-md-3 mb-3 form-control custom-margin"
                              placeholder="QTY"
                              name="item_qty"
                              value={item.item_qty}
                              onChange={(e) => {
                                handleItemChange(index, e);
                                handle_get_tot_item_price(e, index);
                                handle_get_total_sub_total(e);
                              }}
                              required
                              tabIndex="0"
                              id="outlined-basic"
                              label="QTY"
                              variant="outlined"
                            />
                          </div>
                          <div className="col-md-3">
                            <TextField
                              type="text"
                              className="col-md-12 mb-3 custom-margin"
                              placeholder="Price"
                              name="item_tot_price"
                              value={item.item_tot_price}
                              onChange={(e) => handleItemChange(index, e)}
                              required
                              tabIndex="0"
                              id="outlined-basic"
                              label="Price"
                              variant="outlined"
                              style={{ marginRight: "10px" }}
                              disabled
                              InputLabelProps={{ shrink: true }} // Set the shrink property to true
                            />
                          </div>
                          <div
                            className="row mb-3"
                            style={{ justifyContent: "end" }}
                          >
                            {index == invoiceData.inv_items.length - 1 ? (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-primary col-md-1 "
                                  onClick={addNewItem}
                                  style={{ marginRight: "10px" }}
                                >
                                  +
                                </button>
                                {index !== 0 ? (
                                  <button
                                    type="button"
                                    className="btn btn-primary col-md-1"
                                    onClick={() => removeItem(index)}
                                  >
                                    x
                                  </button>
                                ) : null}
                              </>
                            ) : null}
                          </div>
                        </div>
                      ))}
                    <div
                      className=""
                      style={{
                        display: "flex",
                        justifyContent: "start",
                      }}
                    >
                      <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "30%",
                        }}
                      >
                        <label style={{ fontSize: "1.2em" }}>
                          <input
                            type="checkbox"
                            ref={inv_gj_inputRef}
                            onClick={(e) => handle_check_gj(e)}
                            // defaultChecked = {invoiceData.inv_cgst == "0" && invoiceData.inv_igst_final !== "0" ? setIsGjChecked(false) : setIsGjChecked(true)}
                            checked={isGjChecked}
                          />{" "}
                          GJ
                        </label>

                        <label style={{ fontSize: "1.2em" }}>
                          <input
                            type="checkbox"
                            ref={inv_tds_inputRef}
                            onClick={(e) => handle_check_international(e)}
                            checked={isInternationChecked}
                          />{" "}
                          International
                        </label>
                      </div>
                    </div>
                    <div className="grand_total_txt1">
                      Sub Total &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;:-{" "}
                      {invoiceData.inv_subtotal}
                    </div>

                    {invoiceData.inv_sgst !== "0" ? (
                      <>
                        {" "}
                        <div className="grand_total_txt1">
                          SGST (9%) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:-{" "}
                          {invoiceData.inv_sgst}
                        </div>
                        <div className="grand_total_txt1">
                          CGST (9%) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:-{" "}
                          {invoiceData.inv_cgst}
                        </div>
                      </>
                    ) : null}
                    {invoiceData.inv_igst_final !== "0" &&
                    invoiceData.inv_igst_final !== null ? (
                      <div className="grand_total_txt1">
                        IGST (18%)
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:-{" "}
                        {invoiceData.inv_igst_final}
                      </div>
                    ) : null}
                    <div className="grand_total_txt1">
                      Grand Total &nbsp;&nbsp;&nbsp;:-{" "}
                      {invoiceData.inv_grand_total}
                    </div>
                    <button type="submit" className="btn btn-outline-success">
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </center>
      <section style={{ marginTop: "5%" }}>
        <Line_Chart data={getRefresh} />
      </section>
    </div>
  );
};

export default ShowInvoice;
