import React, { useEffect, useState } from "react";
import "../Login/Login.css";
import company_logo1 from "./Assets/Asset 22.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Cookies from "js-cookie";
// import { API_URL } from "../../Config.js";
import { Link } from 'react-router-dom'
import API_URL from "../../Config";
import logo1 from './Assets/Asset 23.png'


const Login = () => {
  const [data, setData] = useState();
  const [showEyeIcon, setShowEyeIcon] = useState(false);

  const handle_Change = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    
    Cookies.remove("id");
  }, []);
  const handle_Submit = async (e) => {
    e.preventDefault();
    await axios.post(`${API_URL}/admin_verify`, data).then((res) => {
      if (res.data) {

        toast.success("Login Successfull !!!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          style: { fontFamily: "poppins" },
          // transition: Bounce,
        });
        Cookies.set("id", res.data._id);
        window.location = "/show_invoices";
      } else if (res.status == 225) {
        toast.error("Credentials Not Matched...", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          style: { fontFamily: "poppins" },
          // transition: Bounce,
        });
      } else if (res.data == false) {
        toast.error("Credentials Not Matched...", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          style: { fontFamily: "poppins" },
          // transition: Bounce,
        });
      }
    });
  };

  const handle_showPassword = () => {
    setShowEyeIcon(!showEyeIcon);
  };

  return (
    <section class="vh-98">
    <ToastContainer/>
      <div style={{ width: "100%", display: "flex" }}>

      </div>
      <div class="container py-5 h-100">
        <div class="row d-flex align-items-center justify-content-center h-100">
       
          <div class="col-md-7 col-lg-5 col-xl-5 offset-xl-1 ">
            <form
              className="admin_login_form1"
              onSubmit={(e) => handle_Submit(e)}
            >
              <div
                style={{
                  width: "100%",
                  marginBottom: "10%",
                  fontSize: "1.54em",
                  fontWeight: "400",
                }}
              >
                <img src={logo1} alt="" style={{width : "300px"  }} />
              </div>
              <div
                data-mdb-input-init
                class="form-outline mb-4"
                style={{ textAlign: "start" }}
              >
                <label class="form-label" for="form1Example13">
                  Username
                </label>
                <input
                  type="text"
                  id="form1Example13"
                  class="form-control form-control-lg"
                  required
                  name="username"
                  onChange={(e) => handle_Change(e)}
                  style={{ textTransform: "none" }}
                />
              </div>


              <div
                data-mdb-input-init
                class="form-outline mb-4"
                style={{ textAlign: "start" }}
              >
                <label class="form-label" for="form1Example23">
                  Password
                </label>
                <div class="input-group">
                  <input
                    type={showEyeIcon == false ? "password" : "text"}
                    id="form1Example23"
                    class="form-control form-control-lg"
                    required
                    name="password"
                    onChange={(e) => handle_Change(e)}
                    style={{ textTransform: "none" }}
                  />
                  <button
                    class="btn"
                    type="button"
                    id="togglePassword"
                    style={{ border: "1px solid rgb(223, 226, 230)" }}
                    onClick={() => handle_showPassword()}
                  >
                    {showEyeIcon == false ? (
                      <RemoveRedEyeIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </button>
                </div>
              </div>
            
              <div class="d-flex justify-start-around align-items-center mb-4">
               
                
               
              </div>

              

              <button
                type="submit"
                data-mdb-button-init
                data-mdb-ripple-init
                class="btn btn-primary btn-lg btn-block"
              >
                Sign in
              </button><br /><br />
            
              
             
            </form>
            
          </div>
          
        </div>
      </div>
    </section>
  );
};

export default Login;
