import React, { useEffect, useState } from "react";
import "./View_Invoice.css";
import invoice_header_logo from "./Assets/top_icon.svg";
import phone_icon from './Assets/Frame.svg'
import location_icon from './Assets/Frame 2.svg'
import email_icon from './Assets/Group.svg'
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import invoice_txt from './Assets/INVOICE.svg'
import { useParams } from "react-router-dom";
import axios from "axios";
import API_URL from "../../Config";
import { toast } from "react-toastify";
import Loaderrr from "../../Loader/Loader";

const ViewInvoice = () => {
  const { ids } = useParams()
  const [ getLoader , setLoader ] = useState(false)
  const [ getData , setData ] = useState([])

  const handle_get_data = async()=>{
    setLoader(true)

    await axios.post(`${API_URL}/get_one_invoice` , { id : ids }).then((res)=>{
      if(res.data.success == true){
        setData(res.data.data )
    setLoader(false)

      }
      else{
        toast.error(`${res.data.data}`)
      }
    })
  }

  useEffect(()=>{
    handle_get_data()
  },[])

  const captureContent = async () => {
    const content = document.getElementById("myTable1"); // Replace 'myTable1' with the id of the element you want to capture
    const height = content.scrollHeight; // Get the scroll height of the content
    const canvas = await html2canvas(content, {
        scale: 5, // Decrease scale for lower resolution
        logging: true, // Enable logging to troubleshoot any issues
        scrollY: -window.scrollY, // Correct scroll offset
        windowWidth: document.documentElement.offsetWidth, // Use document width
        windowHeight: height, // Set canvas height to content height
        allowTaint: true, // Allow images from other domains
        useCORS: true, // Enable cross-origin resource sharing
        dpi: window.devicePixelRatio * 300, // Increase DPI for higher resolution
    });
    return canvas;
};

  const generatePDF = async () => {
    setLoader(true)
    const canvas = await captureContent();
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF();
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

    // Convert the PDF to a Blob
    const pdfBlob = pdf.output("blob");

    // Create object URL for the Blob
    const pdfObjectURL = URL.createObjectURL(pdfBlob);

    // Open the PDF in a new tab
    window.open(pdfObjectURL, "_blank");
    setLoader(false)
};



  return (
    <center>
    {getLoader == true ? <Loaderrr/> : null }
      <div className="invoice_main_container" id="myTable1">
        <div className="invoice_header_container">
          <img
            src={invoice_header_logo}
            alt=""
            className="invoice_header_logo"
          />
          <div className="invoice_header_title_container">
            <div className="invoice_header_title1"><img src={invoice_txt} alt="" /></div>
            <div className="invoice_header_title2_container">
              <div className="invoice_header_title21_container">
                <div className="invoice_header_title2_container_txt1">
                  <div>Invoice Number</div>
                </div>
                <div className="invoice_header_title2_container_txt2">
                  <div>Invoice Date</div>
                </div>
              </div>
              <div className="invoice_header_title21_container">
                <div className="invoice_header_title2_container_txt3 text-end">
                  <div>{getData.inv_no}</div>
                </div>
                <div className="invoice_header_title2_container_txt4 text-end">
                  <div>{new Date(getData.inv_date).toDateString()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="invoice_to_title1">Invoice To:</div>
        <div className="invoice_to_title2">Skybyte Digital</div>
        <div className="invoice_to_title3">Address :</div>
        <div className="invoice_to_title4">
          801 - Royal Trade Centre, Hazira - Adajan Rd, Opp Star Bazaar, Adajan,
          Surat, Gujarat 395009
        </div>
        <div className="invoice_to_title5">Phone : 
        <span style={{ color : "#61647A" , fontWeight : "500" , marginLeft : "2px"  }}>+91 96019 63003</span>
       </div>
       <div className="invoice_to_title6">GSTIN :  
        <span style={{ color : "#61647A" , fontWeight : "500" , marginLeft : "2px" }}>24IAMPS4256N1ZU</span>
       </div>

       <div className="invoice_from_title1">From To:</div>
       <div className="invoice_from_title2">{getData.inv_c_name}</div>
       <div className="invoice_from_title3">Address :</div>
       <div className="invoice_from_title4">{getData.inv_c_address}</div>
       <div className="invoice_from_title5">Phone : 
        <span style={{ color : "#61647A" , fontWeight : "500" , marginLeft : "2px" }}>{getData.inv_c_ph_no}</span>
       </div>
       <div className="invoice_from_title6">GSTIN :
        <span style={{ color : "#61647A" , fontWeight : "500" , marginLeft : "2px" }}>{getData.inv_c_gstin}</span>
       </div>
       {/* <div className="invoice_table_title_box1">No.</div> */}
       {/* <div className="invoice_table_title_box1">No.</div> */}
       <div className="invoice_table_title">
       <div className="invoice_table_title_box1">No.</div>
       <div className="invoice_table_title_box2">Service Description</div>
       <div className="invoice_table_title_box1 invoice_table_title_box3">PRICE</div>
       <div className="invoice_table_title_box1 invoice_table_title_box3">QTY.</div>
       <div className="invoice_table_title_box1 invoice_table_title_box3">PRICE</div>
      

       </div>
       {/* <div className="invoice_table_items">
       <div className="invoice_table_title_box1">01.</div>
       <div className="invoice_table_title_box2">Shopify website development service </div>
       <div className="invoice_table_title_box1 invoice_table_title_box3">₹.30000</div>
       <div className="invoice_table_title_box1 invoice_table_title_box3">1</div>
       <div className="invoice_table_title_box1 invoice_table_title_box3">₹.30000</div>
      

       </div> */}
       
       {getData.inv_items ? (
        getData.inv_items.map((item, index) => (
          <div className="invoice_table_items" key={index}>
            <div className="invoice_table_title_box1">{index + 1}.</div>
            <div className="invoice_table_title_box2">{item.item_desc}</div>
            <div className="invoice_table_title_box1 invoice_table_title_box3">
              ₹ {item.item_price}
            </div>
            <div className="invoice_table_title_box1 invoice_table_title_box3">
              {item.item_qty}
            </div>
            <div className="invoice_table_title_box1 invoice_table_title_box3">
              ₹ {item.item_tot_price}
            </div>
          </div>
        ))
      ) : (
null        // You can also add a loading spinner or indicator here
      )}

       <div className="invoice_payment_info_title1">PAYMENT INFO :</div>
       <div className="invoice_payment_info_title2">Bank Name: 
        <span style={{ color : "#61647A" , fontWeight : "500" , marginLeft : "2px" }}>Indusind Bank</span>
       </div>
       <div className="invoice_payment_info_title2 invoice_payment_info_title3">Account Name:  
        <span style={{ color : "#61647A" , fontWeight : "500" , marginLeft : "2px" }}>SKYBYTE DIGITAL SOLUTION PVT. LTD.</span>
       </div>
      <div className="invoice_payment_info_title2 invoice_payment_info_title4">A/C no.:  
        <span style={{ color : "#61647A" , fontWeight : "500" , marginLeft : "2px" }}>259277755508</span>
       </div>
       <div className="invoice_payment_info_title2 invoice_payment_info_title5">IFSC:  
        <span style={{ color : "#61647A" , fontWeight : "500" , marginLeft : "2px" }}>INDB0001058</span>
       </div>
       <div className="invoice_payment_info_title2 invoice_payment_info_title6">Email:   
        <span style={{ color : "#61647A" , fontWeight : "500" , marginLeft : "2px" }}>skybytedigital@gmail.com</span>
       </div>
       <div className="invoice_total_container1">
       <div className="invoice_subtotal">
        <span className="invoice_subtotal_txt1">Subtotal:</span>
        <span className="invoice_subtotal_txt2"> ₹ {getData.inv_subtotal}/-</span>
       </div>
      
       
       {getData.inv_sgst !== "0" ?   <div className="invoice_sgst" style={{ marginTop : "2%" }}>
        <span className="invoice_subtotal_txt1" >SGST (9%):</span>
        <span className="invoice_subtotal_txt2"> ₹ {getData.inv_sgst}/-</span>
       </div> : null}
       {getData.inv_cgst !== "0" ?   <div className="invoice_cgst" style={{marginTop : "2%"}} >
        <span className="invoice_subtotal_txt1" >CGST (9%):</span>
        <span className="invoice_subtotal_txt2"> ₹ {getData.inv_cgst}/-</span>
       </div> : null}
       {getData.inv_igst_final !== "0" && getData.inv_igst_final !== "NaN" ?  <div className="invoice_cgst" style={{marginTop : "3.4%"}}>
        <span className="invoice_subtotal_txt1">IGST (18%):</span>
        <span className="invoice_subtotal_txt2"> ₹ {getData.inv_igst_final}/-</span>
       </div> : null}
      
       <div className="invoice_main_total">
        <span className="invoice_subtotal_txt1" style={{color:'white' , fontWeight:"700"}}>Total :</span>
        <span className="invoice_subtotal_txt2" style={{color:'white' , fontWeight:"700"}}> ₹ {getData.inv_grand_total}/-</span>
       </div>
       </div>
       <div className="invoice_thankyou_title">Thank you for your purchase! <b>Skybyte Digital Solution Pvt. Ltd.</b></div>
       <div className="invoice_footer_container">
        <div className="footer_item_container">
          <div className="footer_item_box_1">
            <img src={email_icon} alt="" />
            <div className="footer_item_box_1_txt">skybytedigital@gmail.com</div>
          </div>
          <div className="footer_item_box_1 footer_item_box_2 ">
            <img src={phone_icon} alt="" />
            <div className="footer_item_box_1_txt">+91 96019 63003</div>
          </div>
          <div className="footer_item_box_1 footer_item_box_3">
            <img src={location_icon} alt="" />
            <div className="footer_item_box_1_txt footer_item_box_3_txt" style={{fontSize:"9px"}}>801 - Royal Trade Centre, Hazira - Adajan Rd,
            Opp Star Bazaar, Adajan, Surat, Gujarat 395009</div>
          </div>
        </div>
       </div>
      </div>
      <button onClick={generatePDF} className="btn btn-outline-success mt-3">Genrate PDF</button>
    </center>
  );
};

export default ViewInvoice;
