import React, { useEffect, useState } from "react";
import axios from "axios";
import API_URL from "../../Config";
import { toast } from "react-toastify";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import CountUp from "react-countup";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import Cookies from "js-cookie";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Link } from "react-router-dom";
import StoreIcon from "@mui/icons-material/Store";
import CategoryIcon from "@mui/icons-material/Category";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import "./Line_Chart.css";

const Line_Chart = ({data}) => {
  const [getData, setData] = useState([]);
  const [admin_dashboard_details, set_admin_dashboardDetails] = useState([]);

  const handleGetData = async (filterBy) => {
    try {
      const response = await axios.post(
        `${API_URL}/count_invoices_data_report`,
        { filterBy }
      );
      const responseData = response.data;

      if (responseData.success) {
        setData(responseData.data); // Update state with fetched data
      } else {
        toast.error(responseData.data);
      }
    } catch (error) {
      toast.error("Error fetching data");
    }
  };

  const [getCustomDate, setCustomDate] = useState({
    startDate: "",
    endDate: "",
  });

  const handle_custom_date = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    await setCustomDate({ ...getCustomDate, [name]: value });

    // Use the updated values directly from the event
    await axios
      .post(`${API_URL}/count_invoices_data_report`, {
        filterBy: "custom",
        startDate: name === "startDate" ? value : getCustomDate.startDate,
        endDate: name === "endDate" ? value : getCustomDate.endDate,
      })
      .then((res) => {
        if (res.data.success) {
          setData(res.data.data);
        } else {
          toast.error(`${res.data.data}`);
        }
      })
      .catch((error) => {
        // Handle error state if needed
      });
  };

  const handle_get_admin_dashboard_details = async () => {
    await axios.post(`${API_URL}/get_dashboard_details`).then((res) => {
      if (res.data.success == true) {
        set_admin_dashboardDetails(res.data.data);
      }
    });
  };

  useEffect(() => {
    handleGetData("1days"); // Fetch initial data on component mount
    handle_get_admin_dashboard_details();
  }, [data]);

  return (
    <div>
      <div className="row admin_dashboard_card_container">
        <div className="col-md-3 admin_dashboard_card admin_dashboard_card1">
          <div className="dash-box-info">
            {/* <SupervisorAccountIcon
              style={{ fontSize: "26px", color: "white" }}
            /> */}
            <CountUp
              end={admin_dashboard_details.tot_pending}
              style={{ fontSize: "26px", fontWeight: "500", color: "white" }}
            />
          </div>
          <div className="box-info-link">
            <Link to="/show_invoices">
              Total Pending Invoice <ArrowRightAltIcon />
            </Link>
          </div>
        </div>

        <div className="col-md-3 admin_dashboard_card admin_dashboard_card2">
          <div className="dash-box-info">
            <CountUp
              end={admin_dashboard_details.tot_pending_amount}
              style={{ fontSize: "26px", fontWeight: "500", color: "white" }}
            />
          </div>
          <div className="box-info-link">
            <Link to="/show_invoices">
              Total Pending Invoice Amount <ArrowRightAltIcon />
            </Link>
          </div>
        </div>

        <div className="col-md-3 admin_dashboard_card admin_dashboard_card3">
          <div className="dash-box-info">
            <CountUp
              end={admin_dashboard_details.tot_paid}
              style={{ fontSize: "26px", fontWeight: "500", color: "white" }}
            />
          </div>
          <div className="box-info-link">
            <Link to="/show_invoices">
              Total Paid Invoice
              <ArrowRightAltIcon />
            </Link>
          </div>
        </div>

        <div className="col-md-3 admin_dashboard_card admin_dashboard_card4">
          <div className="dash-box-info">
            <CountUp
              end={admin_dashboard_details.tot_paid_amount}
              style={{ fontSize: "26px", fontWeight: "500", color: "white" }}
            />
          </div>
          <div className="box-info-link">
            <Link to="/show_invoices">
              Total Paid Invoice Amount <ArrowRightAltIcon />
            </Link>
          </div>
        </div>
      </div>

      <div className="dropdown">
       
        <button
          className="btn btn-secondary dropdown-toggle mt-3"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style={{ marginTop: "5%" , left : "0" , position : "absolute" , marginLeft : "3%" , zIndex : "2"  }}
        >
          Filter By
        </button>

        <ul
          className="dropdown-menu"
          aria-labelledby="dropdownMenuButton1"
          style={{ padding: "20px" }}
        >
          <li>
            <button
              className="btn btn-success w-100 mb-3"
              onClick={() => handleGetData("1days")}
            >
              Today
            </button>
          </li>
          <li>
            <button
              className="btn btn-success w-100 mb-3"
              onClick={() => handleGetData("yesterday")}
            >
              Yesterday
            </button>
          </li>
          <li>
            <button
              className="btn btn-success w-100 mb-3"
              onClick={() => handleGetData("lastweek")}
            >
              Last Week
            </button>
          </li>
          <li>
            <button
              className="btn btn-success w-100 mb-3"
              onClick={() => handleGetData("lastmonth")}
            >
              Last Month
            </button>
          </li>
          <li>
            <button
              className="btn btn-success w-100 mb-3"
              onClick={() => handleGetData("currentmonth")}
            >
              Current Month
            </button>
          </li>
          <li>
            <button
              className="btn btn-success w-100 mb-3"
              onClick={() => handleGetData("last6month")}
            >
              Last 6 Month
            </button>
          </li>
          <li>
            <button
              className="btn btn-success w-100 mb-3"
              onClick={() => handleGetData("lastyear")}
            >
              Last Year
            </button>
          </li>
          <li>
            <div>
              <input
                type="date"
                // placeholder='Start Date'
                name="startDate"
                onChange={(e) => {
                  // setCustomDate({...getCustomDate,startDate : e.target.value}) ;
                  handle_custom_date(e);
                }}
              />
              <input
                type="date"
                name="endDate"
                // placeholder='End Date'
                onChange={(e) => {
                  //  setCustomDate({...getCustomDate,endDate : e.target.value}) ;
                  handle_custom_date(e);
                }}
              />
            </div>
          </li>
        </ul>
      </div>

      <div style={{ marginTop: "6%" }}>
        <LineChart
          width={1800}
          height={400}
          data={
            Array.isArray(getData.date)
              ? getData.date.map((date, index) => ({
                  date: date, // Convert date string to Date object
                  total_pending: getData.total_pending[index],
                  total_paid: getData.total_paid[index],
                }))
              : []
          }
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            tickFormatter={(tickItem) =>
              new Date(tickItem).toLocaleDateString()
            }
          />
          <YAxis />
          <Tooltip 
    contentStyle={{ backgroundColor: '#f0f0f0', border: '1px solid #e0e0e0' }} 
    labelStyle={{ color: '#333333' }} 
    labelFormatter={(label) => `Date: ${new Date(label).toLocaleDateString()}`} 
    formatter={(value, name) => [value, name === 'total_pending' ? 'Pending' : 'Paid']}
  />          <Legend />
          <Line
            type="monotone"
            dataKey="total_pending"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
          <Line type="monotone" dataKey="total_paid" stroke="#82ca9d" />
        </LineChart>
      </div>
    </div>
  );
};

export default Line_Chart;
